import { ComponentDefinition } from '@wix/editor-platform-sdk-types';
import { ComponentType, ControllerType } from './types';

export function getAppWidgetWrappedSearchBoxDefinition({
  layout,
  layoutResponsive,
  appDefinitionId,
}: {
  layout: ComponentDefinition['layout'];
  layoutResponsive?: any;
  appDefinitionId: string;
}): ComponentDefinition {
  // the add component flow expects the connections to be defined, and
  // it knows to replace the ids with the real ids its going to put there
  const controllerId = 'placeHolderId';
  return {
    componentType: 'platform.components.AppWidget',
    components: [
      {
        componentType: ComponentType.SearchBox,
        data: {
          type: 'SearchBox',
        },
        props: {
          type: 'SearchBoxProperties',
        },
        styleId: 'SearchBox_1',
        skin: 'wixui.skins.SearchBox',
        layout,
        layoutResponsive,
        style: {
          skin: 'wixui.skins.SearchBox',
          componentClassName: 'wixui.SearchBox',
          style: {
            properties: {
              'alpha-inputIconColor': '0.6',
              'alpha-inputBorderColor': '0.6',
              'alpha-buttonBackgroundColor': '0.8',
              'boxShadowToggleOn-inputShadow': 'false',
              inputShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.6)',
              inputFont: 'font_8',
            },
            propertiesSource: {
              'alpha-inputIconColor': 'value',
              'alpha-inputBorderColor': 'value',
              'alpha-buttonBackgroundColor': 'value',
              'boxShadowToggleOn-inputShadow': 'value',
              inputShadow: 'value',
              inputFont: 'value',
            },
          },
        },
        connections: {
          type: 'ConnectionList',
          items: [
            {
              type: 'ConnectionItem',
              role: 'searchBox',
              controllerId,
              isPrimary: true,
            },
          ],
        },
      } as object,
    ],
    layout,
    data: {
      type: 'AppController',
      controllerType: ControllerType.SearchApp,
      applicationId: appDefinitionId,
      settings: JSON.stringify({}),
      id: controllerId,
    },
  } as ComponentDefinition;
}

export function getSearchResultsHeaderComponentDefinition({
  text,
  layout,
}: {
  text: string;
  layout: ComponentDefinition['layout'];
}): ComponentDefinition {
  return {
    componentType: 'wysiwyg.viewer.components.WRichText',
    skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
    layout,
    data: {
      text: `<h1 class="font_0" style="font-size:32px;text-align:center;"><span style="font-size:32px;">${text}</span></h1>`,
      type: 'StyledText',
      stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
    },
  };
}
